import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Icon } from '@keytrade/components-icon';
import { Paragraph } from '@keytrade/components-paragraph';
import { Title } from '@keytrade/components-title';

import HelpBlock, {
  HelpBlockType,
} from '@/components/blocks/HelpBlock/HelpBlock';
import { PageType } from '@/components/Page';
import { getFixedSlugs } from '@/utils/SlugUtils';
import InternalLink from '@/components/InternalLink';
import BlockWrapper from '@/components/BlockWrapper';
import Container from '@/components/Container';
import DefaultLayout from '@/components/DefaultLayout';
import Footer, { FooterData } from '@/components/Footer';
import Navigation, { NavigationData } from '@/components/navigation/Navigation';
import { LinkStyle } from '@/components/Link';
import { LoginData } from '@/components/navigation/Navigation';
import ChatBot from '@/components/chatbot/Chatbot';
import { getBackgroundColor } from '@/utils/StyleUtils';
import useTranslations from '@/hooks/useTranslations';
import Main from '@/components/shared/Main';

const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 32px;
`;

const LinkWrapper = styled.div`
  margin: 60px auto 0;
`;

type Props = PageType & {
  data: {
    contentfulNavigation: NavigationData;
    contentfulFooter: FooterData;
    allContentfulHelpBlock: { nodes: Array<HelpBlockType> };
  };
  contentfulLoginDialog: LoginData;
  location: URL;
};

const LoginErrorEn: React.FC<Props> = (props) => {
  const { t } = useTranslations('login_error');

  const helpblockdata = props.data.allContentfulHelpBlock.nodes[0];
  const contentLogin = props.data.contentfulLoginDialog;
  const contentNavigation = props.data.contentfulNavigation;
  const backgroundColor: string = getBackgroundColor('Light blue');

  return (
    <DefaultLayout>
      <Navigation
        languageSlugs={getFixedSlugs('login-error')}
        pageColor={backgroundColor}
        {...contentLogin}
        {...contentNavigation}
        locale={contentNavigation?.node_locale}
        location={props.location}
      />
      <Main>
        <BlockWrapper color={'White'}>
          <Container>
            <CenterContent>
              <Icon icon='img_fileLost' size='22rem' margin='32px auto 0' />
              <Title level='h2' size='xxxl' center>
                {t('title')}
              </Title>
              <Paragraph margin='24px 0 0'>{t('subtitle')}</Paragraph>
              <LinkWrapper>
                <InternalLink to={'/'} linkStyle={LinkStyle.PRIMARY}>
                  {t('cta_label')}
                </InternalLink>
              </LinkWrapper>
            </CenterContent>
          </Container>
        </BlockWrapper>
      </Main>

      <HelpBlock
        title={helpblockdata?.title}
        faqs={helpblockdata?.faqs}
        supportPageLink={helpblockdata?.supportPageLink}
        locale={props.pageContext.locale}
      />
      <Footer {...props.data.contentfulFooter} />
      <ChatBot locale={contentNavigation?.node_locale} />
    </DefaultLayout>
  );
};

export default LoginErrorEn;

export const LoginErrorEnQuery = graphql`
  query LoginErrorEnQuery($locale: String) {
    contentfulNavigation(node_locale: { eq: $locale }) {
      node_locale
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    allContentfulHelpBlock(
      filter: { name: { eq: "missinghelpblock" }, node_locale: { eq: $locale } }
    ) {
      nodes {
        node_locale
        title
        name
        faqs {
          ...FAQItemFragment
        }
        supportPageLink {
          ...LinkFragment
        }
      }
    }
  }
`;
